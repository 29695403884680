<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Alat</b></div>
      <b-button
        squared
        @click="$router.push('/tools/add')"
        v-if="btnAccess"
        variant="primary"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!-- Filter -->
              <div class="row justify-content-end mt-n3">
                <div class="col-md-3">
                  <treeselect
                    v-model="filter.tool_category_id"
                    :multiple="false"
                    placeholder="Saring Kategori Alat"
                    :options="tool_categories"
                    @input="filterByToolCategory"
                  />
                </div>
                <div class="col-md-3">
                  <treeselect
                    v-model="filter.tool_status_id"
                    :multiple="false"
                    placeholder="Saring Status Alat"
                    :options="tool_status_options"
                    @input="filterByToolStatus"
                  />
                </div>
                <div class="col-md-5">
                  <b-input-group>
                    <template #append>
                      <b-button
                        variant="success"
                        squared
                        size="sm"
                        @click="search"
                      >
                        Cari
                      </b-button>
                      <b-button
                        variant="danger"
                        squared
                        size="sm"
                        @click="reset"
                      >
                        Reset
                      </b-button>
                    </template>
                    <b-form-input
                      type="text"
                      v-model="filter.name"
                      placeholder="Cari Nama/No. Serial/No. Produk/Merk"
                    ></b-form-input>
                  </b-input-group>
                </div>
              </div>
              <div class="row justify-content-end">
                <b-button
                  class="mr-1"
                  style="background-color: white; border-color: #107c41"
                  v-b-tooltip.hover
                  title="Download Excel"
                  @click="$bvModal.show('modal-import-excel')"
                >
                  <i class="fas fa-file-excel px-0" style="color: #107c41"></i> Import Excel
                </b-button>
                <vue-excel-xlsx
                  :data="itemsNoPagination"
                  :columns="columns"
                  :filename="`Daftar Alat`"
                  :sheetname="`Daftar Alat`"
                >
                  <b-button
                    class="mr-1"
                    style="background-color: #107c41"
                    v-b-tooltip.hover
                    title="Download Excel"
                  >
                    <i class="fas fa-file-excel text-white px-0"></i>
                  </b-button>
                </vue-excel-xlsx>
              </div>
              <b-table
                striped
                hover
                :items="items"
                :fields="currentUser.role_id == 2 ? fieldStudent : fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '15%' : '' }"
                  />
                </template>

                <template #cell(photo)="data">
                  <div
                    class="
                      d-flex
                      justify-content-start
                      align-items-center
                      image-container
                    "
                  >
                    <div class="image-input-wrapper" style="position: relative">
                      <img class="image" :src="data.item.photo_url" />
                    </div>
                  </div>
                </template>

                <template #cell(name)="data">
                  <span>{{
                    data.item.name + "/" + data.item.tool_category_name
                  }}</span>
                </template>

                <template #cell(brand)="data">
                  <span>{{
                    data.item.brand +
                    "/" +
                    data.item.serial_number +
                    "/" +
                    data.item.product_number
                  }}</span>
                </template>

                <template #cell(status)="data">
                  <b-badge
                    v-if="data.item.tool_status_id == 1"
                    class="btn-green text-white"
                    style="cursor: pointer"
                    @click="
                      btnStatusAccess == true
                        ? showModalStatus(
                            data.item.id,
                            data.item.tool_status_id
                          )
                        : ''
                    "
                  >
                    {{ data.item.tool_status_name }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.tool_status_id == 2"
                    class="text-white"
                    variant="danger"
                    style="cursor: pointer"
                    @click="
                      btnStatusAccess == true
                        ? showModalStatus(
                            data.item.id,
                            data.item.tool_status_id
                          )
                        : ''
                    "
                  >
                    {{ data.item.tool_status_name }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.tool_status_id == 3"
                    class="text-white"
                    variant="info"
                    style="cursor: pointer"
                    @click="
                      btnStatusAccess == true
                        ? showModalStatus(
                            data.item.id,
                            data.item.tool_status_id
                          )
                        : ''
                    "
                  >
                    {{ data.item.tool_status_name }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.tool_status_id == 4"
                    class="text-white"
                    variant="success"
                    style="cursor: pointer"
                    @click="
                      btnStatusAccess == true
                        ? showModalStatus(
                            data.item.id,
                            data.item.tool_status_id
                          )
                        : ''
                    "
                  >
                    {{ data.item.tool_status_name }}
                  </b-badge>
                  <b-badge
                    v-if="data.item.tool_status_id == 5"
                    class="text-white"
                    variant="warning"
                    style="cursor: pointer"
                    @click="showDetailBorrower(data.item)"
                  >
                    {{ data.item.tool_status_name }}
                  </b-badge>
                </template>

                <template #cell(price)="data">
                  <span>{{ data.item.price != 0 ? data.item.price : "" }}</span>
                </template>

                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-primary"
                    variant="outline-primary"
                    v-b-tooltip.hover
                    title="Duplikat"
                    @click="
                      $router.push({
                        path: '/tools/duplicate/' + data.item.id,
                      })
                    "
                  >
                    <i class="fas fa-clone px-0"></i>
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({
                        path: '/tools/detail/' + data.item.id,
                      })
                    "
                    ><i class="fas fa-eye px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="
                      $router.push({
                        path: '/tools/edit/' + data.item.id,
                      })
                    "
                    v-if="btnAccess"
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="btn-circle btn-outline-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                    v-if="btnAccess"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </template>
              </b-table>

              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Status -->
    <b-modal id="modal-status" @ok="changeStatus">
      <template #modal-title>Status</template>
      <b-form-group label="" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="radio-group-2"
          v-model="formStatus.tool_status_id"
          :aria-describedby="ariaDescribedby"
          name="radio-sub-component"
        >
          <template v-for="item in tool_statuses">
            <b-form-radio :key="item.id" :value="item.id" v-if="item.id != 5">{{
              item.name
            }}</b-form-radio>
          </template>
        </b-form-radio-group>
      </b-form-group>
    </b-modal>

    <!-- Modal Detail Borrower -->
    <b-modal id="modal-detail-borrower" hide-footer>
      <template #modal-title>Detail Peminjaman</template>
      <table class="table">
        <tr>
          <th>Nama Alat </th>
          <th>:</th>
          <td>{{detailBorrower.name}}</td>
        </tr>
        <tr>
          <th>Tanggal Dipinjam: </th>
          <th>:</th>
          <td>{{detailBorrower.borrow_date}}</td>
        </tr>
        <tr>
          <th>Peminjam: </th>
          <th>:</th>
          <td>{{detailBorrower.borrower_name}}</td>
        </tr>
        <tr>
          <th>Petugas: </th>
          <th>:</th>
          <td>{{detailBorrower.officer_name}}</td>
        </tr>
      </table><b-button class="mt-3" block variant="primary" @click="$bvModal.hide('modal-detail-borrower')">Tutup</b-button>
    </b-modal>

    <!-- Modal Import -->
    <b-modal id="modal-import-excel" size="lg" @ok="importTool">
      <template #modal-title>Import Alat</template>
      <vue-excel-xlsx
        :data="itemImport"
        :columns="templateImport"
        :filename="`Template Impor Alat`"
        :sheetname="`Template Impor Alat`"
      >
        <b-button
          class="mr-1"
          style="background-color: #107c41"
          v-b-tooltip.hover
          title="Download Excel"
        >
          <i class="fas fa-file-excel text-white px-0"></i>
          <span class="text-white ml-2">Download template</span>
        </b-button>
      </vue-excel-xlsx>

      <div class="row my-2">
        <div class="col-12">
          <fieldset style="border: 1px solid #dee2e6; padding: 8px">
            <legend
              style="
                border: 1px solid #dee2e6;
                padding: 9px;
                margin-left: 13px;
                font-size: initial;
                width: initial;
                background: white;
              "
            >
              Syarat File Excel
            </legend>
            <ul class="ml-4">
              <li>File yang akan diimport harus sesuai dengan template yang bisa didownload diatas ini.</li>
              <li>Kolom tgl_beli dan tgl_inventaris pada file template berformat YYYY-MM-DD(0000-00-00)</li>
              <li>Kolom yang wajib diisi hanya nama, kategori dan spesifikasi</li>
            </ul>
          </fieldset>
        </div>
      </div>

      <b-form-group id="input-group-image">
        <label for="input-image">Import Alat: </label>
        <b-form-file
          v-model="formImport.file"
          placeholder="Import Alat"
          drop-placeholder="Lepaskan file di sini..."
          accept=".csv, .xls, .xlsx"
          @change="onFileUpload"
          ref="parental"
        ></b-form-file>
        <small class="text-danger">{{ errorImport.file }}</small>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  data() {
    return {
      // filter
      filter: {
        name: "",
        tool_status_id: "",
        tool_category_id: "",
      },
      currentUser: getUser(),
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama/Kategori",
          sortable: true,
        },
        {
          key: "brand",
          label: "Merk/No. Seri/No. Produk",
          sortable: true,
        },
        {
          key: "location",
          label: "Penyimpanan",
          sortable: true,
        },
        {
          key: "price",
          label: "Harga (Rp.)",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      fieldStudent: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama/Kategori",
          sortable: true,
        },
        {
          key: "brand",
          label: "Merk/No. Seri/No. Produk",
          sortable: true,
        },
        {
          key: "location",
          label: "Penyimpanan",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      columns: [
        {
          field: "name",
          label: "Nama",
        },
        {
          field: "tool_category_name",
          label: "Kategori",
        },
        {
          field: "brand",
          label: "Merek",
        },
        {
          field: "serial_number",
          label: "No. Seri",
        },
        {
          field: "product_number",
          label: "No. Produk",
        },
        {
          field: "location",
          label: "Harga (Rp.)",
        },
        {
          field: "price_display",
          label: "Harga",
        },
        {
          field: "specification",
          label: "Spesifikasi",
        },
        {
          field: "sop_usage",
          label: "SOP Penggunaan",
        },
        {
          field: "buy_date_display",
          label: "Tanggal Pembelian",
        },
        {
          field: "inventory_date_display",
          label: "Tanggal Tercatat Inventaris",
        },
        {
          field: "tool_status_name",
          label: "Status",
        },
      ],
      templateImport: [
        {
          field: "nama",
          label: "nama",
        },
        {
          field: "kategori",
          label: "kategori",
        },
        {
          field: "spesifikasi",
          label: "spesifikasi",
        },
        {
          field: "noserial",
          label: "noserial",
        },
        {
          field: "noproduk",
          label: "noproduk",
        },
        {
          field: "harga",
          label: "harga",
        },
        {
          field: "sop",
          label: "sop",
        },
        {
          field: "merek",
          label: "merek",
        },
        {
          field: "lokasi",
          label: "lokasi",
        },
        {
          field: "tgl_beli",
          label: "tgl_beli",
        },
        {
          field: "tgl_inventaris",
          label: "tgl_inventaris",
        },
      ],
      itemImport: [{ nama: "", spesifikasi: "" }],
      items: [],
      itemsNoPagination: [],
      client_types: [],
      tool_statuses: [],
      tool_status_options: [],
      tool_categories: [],
      //formStatus
      formStatus: {
        tool_status_id: 0,
        id: 0,
      },
      // formImport
      formImport: {},
      errorImport: {
        file: "",
      },
      detailBorrower: {},
      // access
      btnAccess: false,
      btnStatusAccess: false,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&tool_category_id=${this.filter.tool_category_id}&tool_status_id=${this.filter.tool_status_id}`;
      let response = await module.paginate(
        "api/tools",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let responseNoPagination = await module.paginate(
        "api/tools-no-pagination",
        `?${filterParams}`
      );
      this.itemsNoPagination = responseNoPagination.data;
      console.log("responseNoPagination", responseNoPagination);

      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getToolCategoryOption() {
      let response = await module.setTreeSelect("api/tool-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.tool_categories = response.data;
        this.tool_categories.unshift({
          label: "Saring Kategori Alat",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getToolStatusOption() {
      let response = await module.setTreeSelect("api/tool-statuses");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.tool_status_options = response.data;
        this.tool_status_options.unshift({
          label: "Saring Status Alat",
          id: "",
          isDisabled: true,
        });
      }
    },
    async getToolStatus() {
      this.tool_statuses = await module.get("api/tool-statuses");
    },

    filterByToolStatus(evt) {
      if (typeof evt == "undefined") {
        this.filter.tool_status_id = "";
      }
      this.pagination();
    },
    filterByToolCategory(evt) {
      if (typeof evt == "undefined") {
        this.filter.tool_category_id = "";
      }
      this.pagination();
    },

    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.filter.tool_status_id = "";
      this.filter.tool_category_id = "";
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/tools/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    onFileUpload(evt) {
      this.formImport.file = evt.target.files[0];
      console.log("formImport", this.formImport);
    },

    async importTool() {
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.formImport)) {
        formData.append(key, value);
      }

      let response = await module.submit(formData, `api/tools/import`);
      if (response.state == "error") {
        Swal.fire({
          title: "Gagal",
          text: "Gagal import alat",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-import-excel");
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-import-excel");
        this.pagination();
      }
    },

    showDetailBorrower(data){
      this.detailBorrower = data
      this.$bvModal.show('modal-detail-borrower')
    },

    async changeStatus() {
      let response = await module.submit(
        this.formStatus,
        `api/tools/${this.formStatus.id}/change-status`
      );
      if (response.state == "error") {
        Swal.fire({
          title: "Gagal",
          text: "Gagal edit status",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-status");
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$bvModal.hide("modal-status");
        this.pagination();
      }
    },

    showModalStatus(id, tool_status_id) {
      this.formStatus.tool_status_id = tool_status_id;
      this.formStatus.id = id;

      this.$bvModal.show("modal-status");
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1104") {
          this.btnAccess = true;
        }
        if (access_right[a] == "1105") {
          this.btnStatusAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Alat", route: "/tools" }]);
    this.pagination();
    this.getToolStatusOption();
    this.getToolStatus();
    this.getToolCategoryOption();
    this.checkAccess();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.image-input-wrapper {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sr-only {
  width: 0px !important;
}
</style>
